import { Component } from '@angular/core';
import { SidenavItem, SidenavService } from 'saturn-angular-lib';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(private sidenavService: SidenavService) {
        this.setupMenu();
    }

    private setupMenu() {
        const menu: SidenavItem[] = [];

        menu.push(new SidenavItem({
            name: 'Dashboard',
            routeOrFunction: '/dashboard',
            icon: 'dvr',
            position: 2,
            pathMatchExact: true
        }));
        menu.push(new SidenavItem({
            name: 'Transactions',
            routeOrFunction: '/transactions',
            icon: 'compare_arrows',
            position: 2,
            pathMatchExact: true
        }));
        menu.push(new SidenavItem({
            name: 'Bank Accounts',
            routeOrFunction: '/bank-accounts',
            icon: 'account_balance',
            position: 2,
            pathMatchExact: true
        }));
        menu.push(new SidenavItem({
            name: 'Products',
            routeOrFunction: '/products',
            icon: 'shopping_cart',
            position: 2,
            pathMatchExact: true
        }));

        menu.push(new SidenavItem({
            name: 'Customer Support',
            routeOrFunction: '/support',
            icon: 'contact_support',
            position: 2,
            pathMatchExact: true
        }));
        // Send all created Items to SidenavService
        menu.forEach(item => this.sidenavService.addItem(item));
    }
}

