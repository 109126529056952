import { Component } from '@angular/core';
import { ActionService, BaseComponent } from 'saturn-angular-lib';

@Component({
    selector: 'fury-toolbar-user-button',
    templateUrl: './toolbar-user-button.component.html',
    styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent extends BaseComponent {

    isOpen: boolean;

    // profile: UserProfile = new UserProfile();

    profile = {};

    constructor(
        // private profileService: ProfileService,
        private actionService: ActionService) {
        super();
        this.loadProfile();
        this.subscribeToProfileChanges();
    }

    toggleDropdown() {
        this.isOpen = !this.isOpen;
    }

    onClickOutside() {
        this.isOpen = false;
    }

    private loadProfile() {
        // this.profileService.getProfile().subscribe(profile => this.profile = profile);
    }

    private subscribeToProfileChanges() {
        // this.actionService.on(UserProfile, [ActionType.UPDATE]).subscribe(msg => {
        //     this.profile = msg.data;
        // });
    }

}
