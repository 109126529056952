import { Component } from '@angular/core';
import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';

@Component({
    selector: 'fury-toolbar-fullscreen-toggle',
    templateUrl: './toolbar-fullscreen-toggle.component.html',
    styleUrls: ['./toolbar-fullscreen-toggle.component.scss']
})
export class ToolbarFullscreenToggleComponent {

    isFullscreen: boolean;

    constructor() {
    }

    toggleFullscreen() {
        const sf = <Screenfull>screenfull;
        if (sf.enabled) {
            sf.toggle();
            this.isFullscreen = !this.isFullscreen;
        }
    }
}
