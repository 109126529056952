import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule',
    },
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

            {
                path: 'dashboard',
                loadChildren: './dashboard/dashboard.module#DashboardModule',
            },
            {
                path: 'products',
                loadChildren: './products/products.module#ProductsModule',
            },
            {
                path: 'profile',
                loadChildren: './profile/profile.module#ProfileModule',
            },
            {
                path: 'bank-accounts',
                loadChildren: './bank-accounts/bank-accounts.module#BankAccountsModule',
            },
            {
                path: 'transactions',
                loadChildren: './transactions/transactions.module#TransactionsModule',
            },
            {
                path: 'support',
                loadChildren: './support/support.module#SupportModule',
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            enableTracing: false,
            paramsInheritanceStrategy: 'always'
        }
    )],
    exports: [RouterModule],
    providers: []
})
export class RoutingModule {
}
