import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SidenavItem, SidenavService } from 'saturn-angular-lib';

@Component({
    selector: 'fury-toolbar-search-bar',
    templateUrl: './toolbar-search-bar.component.html',
    styleUrls: ['./toolbar-search-bar.component.scss']
})
export class ToolbarSearchBarComponent implements OnInit {

    input: string;
    focused: boolean;

    recentlyVisited: SidenavItem[] = [];

    constructor(
        private router: Router,
        private sidenavService: SidenavService) {
    }

    ngOnInit() {

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {

                const item = this.sidenavService.getItemByRoute(event.urlAfterRedirects);

                if (item) {
                    const index = this.recentlyVisited.indexOf(item);
                    if (index > -1) {
                        this.recentlyVisited.splice(index, 1);
                    }

                    this.recentlyVisited.unshift(item);

                    if (this.recentlyVisited.length > 5) {
                        this.recentlyVisited.pop();
                    }
                }
            }

        });
    }


    openDropdown() {
        this.focused = true;
    }

    closeDropdown() {
        this.focused = false;
    }

}
