import { Component } from '@angular/core';
import { SidenavService, SidenavState } from 'saturn-angular-lib';

@Component({
  selector: 'fury-toolbar-sidenav-mobile-toggle',
  templateUrl: './toolbar-sidenav-mobile-toggle.component.html',
  styleUrls: ['./toolbar-sidenav-mobile-toggle.component.scss']
})
export class ToolbarSidenavMobileToggleComponent {

  constructor(private sidenavService: SidenavService) {
  }

  openSidenavMobile() {
    this.sidenavService.sidenavState = SidenavState.MobileOpen;
  }
}
