import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';
import { SidenavItem, SidenavService } from 'saturn-angular-lib';

@Component({
    selector: 'vr-toolbar-navigation',
    templateUrl: './toolbar-navigation.component.html',
    styleUrls: ['./toolbar-navigation.component.scss']
})
export class ToolbarNavigationComponent implements OnInit, OnDestroy {

    sidenavItems: SidenavItem[] = [];
    currentlyOpen$: Observable<SidenavItem[]>;
    showMoreButtonAfterIndex = 8;

    private _routerEventsSubscription: Subscription;

    constructor(private router: Router, private sidenavService: SidenavService) {

        this.sidenavItems.push(new SidenavItem({
            name: 'Dashboard',
            routeOrFunction: '/',
            icon: 'dashboard',
            position: 10,
            pathMatchExact: true
        }));

        this.sidenavItems.push(new SidenavItem({
            name: 'Customers',
            routeOrFunction: 'customers',
            icon: 'dashboard',
            position: 10,
            pathMatchExact: true
        }));
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        this._routerEventsSubscription.unsubscribe();
    }

}
