import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { FooterModule } from '../footer/footer.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BackdropModule, LoadingIndicatorModule, MaterialModule, ScrollbarModule, SidenavModule } from 'saturn-angular-lib';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        LoadingIndicatorModule,

        // Core
        ToolbarModule,
        SidenavModule,
        FooterModule,
        BackdropModule,
        NgScrollbarModule,
        ScrollbarModule,
        SidenavModule,
    ],
    declarations: [
        LayoutComponent,
    ],
    providers: [
        // MediaQueryService
    ]
})
export class LayoutModule {
}
