import { Component, ViewChild } from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
    @ViewChild(NgScrollbar) scrollRef: NgScrollbar;

    constructor(private router: Router) {
        this.initScrollToTop();
    }

    private initScrollToTop() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                this.scrollRef.scrollToTop();
            }
        });
    }
}
